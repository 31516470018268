// TITLE
#homeTitle {
  margin-top: 165px;
  &_wrapper{
    background-color: var(--overlay-7);
    color: var(--dark-grey);
    margin: auto;
    width: max-content;
    padding: 20px;
    h2{
      font-size: 1.6rem;
      margin-top: 0.5rem;
    }
    h3{
      font-size: 1.3rem;
    }
  }
}

// BUTTONS
.homeBtn {
  color: var(--dark-grey) !important;
  padding: 12px;
  border: 1px solid var(--dark-grey);
  background-color: rgba(255, 255, 255, 0.6);
  font-size: 19px;
  margin: 10px;
  margin-left: auto;
  margin-right: auto;
  width: max-content;
  min-width: 10em;
  outline: none;
  display: flex;
  flex-direction: column;
  &:hover {
    background-color: #fff;
  }
  &#topHomeBtn {
    margin-top: 65px;
  }
}
